import * as React from 'react';

import {
    useNavigate,
    useSearchParams
} from 'react-router-dom';

import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    Container, Alert
} from '@mui/material';

import IDataLogin from '../../links/IDataLogin';

import './LogIn.scss';

import CapLogin from '../../components/account/CapLogotin';

import { SyntheticEvent, useState } from "react";
import AlertUnauthorized from "../../components/alerts/AlertUnauthorized";
import AlertServiceError from "../../components/alerts/AlertServiceError";

const LogIn = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [invalidate, setInvalidate] = useState(0);

    const navigate = useNavigate();

    const submitHandler = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget
        // TODO add params from id.rell.ru/signup??application=anyService&continue=https://ocr.rell.ru/
        const formElements = form.elements as typeof form.elements & {
            email: { value: string },
            password: { value: string }
        }

        // * Проверка с файлом типизации (для контроля)
        const data: IDataLogin = {
            email: formElements.email.value,
            password: formElements.password.value,
            application: !searchParams.has("application") ? 'rell-service' : searchParams.get("application"),
            continue: searchParams.has("continue") ? searchParams.get("continue") : 'https://rell.ru'
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data) // передача на сервер данных с отдельного файла констант
        }
        fetch(`${process.env.REACT_APP_BASEURL}/auth/login`, requestOptions)
            .then(async response => {
                const data = await response.json();
                if (response.status === 200) {
                    const continueUrl = data.data['continue'];
                    const identity = data.data['identity'];
                    document.location.href = continueUrl + '?identity=' + identity;
                    return;
                }
                setInvalidate(response.status);
            }).catch(error => {
                setInvalidate(500);
            })
    }

    const alertFactory = () => {
        switch (invalidate) {
            case 0:
                return (<></>);
            case 200:
                return (<Alert color={'success'}>
                    Логин успешен!
                </Alert>);
            case 401:
                return (<AlertUnauthorized />);
            default:
                return (<AlertServiceError />);
        }
    }

    return (
        <div className='wrapper'>
            <Dialog className='register' open={true}>
                <CapLogin label={'Войдите, чтобы продолжить'} />
                <DialogContent>
                    <form onSubmit={submitHandler} >
                        {/* для алертов добавить возможность их закрытия*/}
                        {alertFactory()}
                        <TextField
                            className='register-login'
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            name="email"
                        />
                        <TextField
                            className='register-login'
                            margin="dense"
                            id="password"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="standard"
                        />
                        <Button className='register-btn-resume' type='submit' fullWidth variant="contained">Продолжить</Button>
                    </form>
                </DialogContent>
                <Container className='register-wrp'>
                    {/*<Button variant="text">Не удаётся войти в систему?</Button>*/}
                    {/*-*/}
                    <Button variant="text" onClick={() => navigate('/signup')}>Создать аккаунт</Button>
                </Container>
            </Dialog>
        </div>
    )
}

export default LogIn;
