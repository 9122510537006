import * as React from 'react';

import {
    Alert,
} from '@mui/material';



const ConfirmationAccount = () : JSX.Element => {
    return (
        <>
            <Alert severity="info"
                style={{
                    margin: '0 20px 20px'
                }}>
                На Ваш почтовый ящик отправлено сообщение, содержащее ссылку для подтверждения правильности e-mail адреса. Пожалуйста, перейдите по ссылке для завершения подписки.

                Если письмо не пришло в течение 15 минут, проверьте папку «Спам». Если письмо вдруг попало в эту папку, откройте письмо, нажмите кнопку «Не спам» и перейдите по ссылке подтверждения. Если же письма нет и в папке «Спам», попробуйте подписаться ещё раз. Возможно, вы ошиблись при вводе адреса.
            </Alert>
        </>
    )
}

export default ConfirmationAccount;
