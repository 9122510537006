import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';

import LogIn from './pages/Login/LogIn';
import NewAccount from './pages/Registration/NewAccount';

import reportWebVitals from './reportWebVitals';
import VerificationEmail from "./pages/VerificationEmail/VerificationEmail";
import VerifyEmailSuccess from "./pages/VerifyEmailSuccess/VerifyEmailSuccess2";
import VerifyEmailFail from "./pages/VerifyEmailFail/VerifyEmailFail";
import AccountBlocked from "./pages/AccountBlocked/AccountBlocked";
import AccountAlreadyExist from "./pages/AccountAlreadyExist/AccountAlreadyExist";
import BadRequest from "./pages/BadRequest/BadRequest";
import Error404 from './pages/Error404/Error404';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LogIn />,
  },
  {
    path: "/login",
    element: <LogIn />,
  },
  {
    path: "/signup",
    element: <NewAccount />
  },
  {
    path: '/verification_email',
    element: <VerificationEmail/>
  },
  {
    path: '/verify_email_success',
    element: <VerifyEmailSuccess/>
  },
  {
    path: '/verify_email_fail',
    element: <VerifyEmailFail/>
  },
  {
    path: '/account_is_blocked',
    element: <AccountBlocked/>
  },
  {
    path: '/already_exist',
    element: <AccountAlreadyExist/>
  },
  {
    path: '/bad_request',
    element: <BadRequest/>
  },
  {
    path: '*',
    element: <Error404 />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
