import * as React from 'react';

import {Alert} from "@mui/material";

/**
 * Когда при обращении к серверу возникла ошибка
 * */
const AlertServiceError = () => {

    return (<Alert color={'error'}>
        Упс, у нас ошибка! Мы уже знаем о нем и в скором времени ее решим.
        Ну или перезагрузите страницу, вдруг поможет)))
    </Alert>);

}

export default AlertServiceError;
