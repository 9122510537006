import * as React from 'react';


import {
    Dialog,
    DialogContent,
    Container,
} from '@mui/material';


import CapLogin from "../../components/account/CapLogotin";
import ConfirmationAccount from "../../components/account/ConfirmationAccount";
import {useEffect, useState} from "react";

const VerificationEmail = () => {

    const [emailVerification, setEmailVerification] = useState(false);

    // TOOD exmaple http://localhost:8080/auth/check_verify_email?email=zhulvern-98@mail.ru
    // Проверять примерно каждую секунду + надо отследить что открыта только одна вкладка
    /**
     *    {private Boolean success;
         private String message;
         private UUID userUUID;
         private String username;
         private String email;
     }

     После того как пользователь подтвердил свою почту переходит на логин с алертом что
     ваша учетка подтверждена, пожалуйста авторизуйтесь.
     * */
    useEffect(() => {

        const verify = () => {
            const isVerify = true;
            // any request for isVerify
            if (isVerify) {
                return;
            } else {
                setTimeout(() => verify(), 1000);
            }
        };
        // return () => clearTimeout(timer);
    }, []);

   return (<div className='wrapper'>
            <Dialog className='register' open={true}>
                <CapLogin label={'Регистрация нового аккаунта'}/>
                <DialogContent>
                    <ConfirmationAccount/>
                </DialogContent>
                <Container className='register-wrp'>
                </Container>
            </Dialog>
        </div>
    )
};

export default VerificationEmail;
