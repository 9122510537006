import * as React from 'react';

import {
    useNavigate,
    useSearchParams
} from 'react-router-dom';

import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    Container,
    Box,
    Tooltip,
    Alert
} from '@mui/material';

import {
    Checkbox,
    Link
} from '@mui/joy';

import CapLogin from '../../components/account/CapLogotin';

import './NewAccount.scss';
import { SyntheticEvent } from "react";
import IDataNewAccount from '../../links/IDataNewAccount';

const NewAccount = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [btnRegistr, setBtnRegistr] = React.useState(true);
    const [stErrorFields, setStErrorFields] = React.useState(true);
    const [textTool, setTextTool] = React.useState('Пароль должен содержать больше 5 символов');
    const [savePassword, setSavePassword] = React.useState({ password: '', repeatPassword: '' })
    const [stInput, setStInput] = React.useState({ stName: false, stPassword: false, stPasswordRepeat: false, stchBox: false });
    const navigate = useNavigate();

    const { passwordStrength } = require('check-password-strength'); // библиотека проверки пароля

    const showVerifyEmail = () => {
        navigate('/verification_email')
    }

    const showAccountAlreadyExist = () => {
        navigate('/already_exist')
    }

    const showBadRequest = () => {
        navigate('/bad_request')
    }


    const handlerSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        comparisonPassword();
        // Проверка на заполненность полей формы
        if (stInput.stName === false && stInput.stPassword === false
            && stInput.stPasswordRepeat === false && stInput.stchBox === true) {
            event.preventDefault();

            /// Защита от дурака
            setStErrorFields(false);
            alertErrorRegistration(stErrorFields);

            const form = event.currentTarget
            // TODO add params from id.rell.ru/signup??application=anyService&continue=https://ocr.rell.ru/
            const formElements = form.elements as typeof form.elements & {
                name: { value: string },
                email: { value: string },
                password: { value: string }
            }
            const data: IDataNewAccount = {
                name: formElements.name.value,
                email: formElements.email.value,
                password: formElements.password.value,
                application: !searchParams.has("application") ? 'rell-service' : searchParams.get("application"),
                continue: searchParams.has("continue") ? searchParams.get("continue") : 'https://rell.ru'
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data) // передача на сервер данных с отдельного файла констант
            }

            //TODO  Необходимо выделить домен в конфигурацию для сборки/запуска
            fetch(`${process.env.REACT_APP_BASEURL}/auth/signup`, requestOptions)
                .then(async response => {
                    // изменение dataLink, отдельный файл под константы
                    // setDatalink((d) => [...d, data]);
                    // setInvalidate(response.status);
                    if (response.status === 409) {
                        return showAccountAlreadyExist();
                    }
                    if (response.status === 400) {
                        return showBadRequest();
                    }
                    showVerifyEmail();
                }).catch(error => {
                    console.log('ошибка при авторизации', error);
                    // setInvalidate(500);
                })
        } else {
            event.preventDefault();
            console.log(stInput);
            setStErrorFields(false);
            alertErrorRegistration(stErrorFields);
        }
    }

    /**
     * Функция проверки пароля на слабость
     * @param pass пароль
     * @returns состояние пароля
     */
    const tooltipPassword = (pass: string) => {
        let result = "Пароль слишком слабый";
        setStInput({ ...stInput, stPassword: true });

        switch (pass) {
            case 'Weak':
                result = "Пароль слабый, НЕ содержит: заглавных букв или !@<>";
                setStInput({ ...stInput, stPassword: true });
                break;
            case 'Medium':
                result = "Пароль средний";
                setStInput({ ...stInput, stPassword: false });
                break;
            case 'Strong':
                result = "Пароль сильный";
                setStInput({ ...stInput, stPassword: false });
                break;
        }
        return result;
    }

    /**
     * Проверка имени на количество символов (по умолчанию 3)
     * @param event 
     */
    const checkValidationName = (event: any) => {
        if (event.target.value.length < 3) {
            setStInput({ ...stInput, stName: true })
        } else {
            setStInput({ ...stInput, stName: false })
        }
    }

    /**
     * Проверка и подсказка input пароля
     * Количество знаков больше 5
     * @param event 
     */
    const checkValidationPassword = (event: any) => {
        if (event.target.value.length > 5) {
            setBtnRegistr(false);
            // проверка пароля с помощью библиотеки passwordStrength().value
            let valid = passwordStrength(event.target.value).value;
            let resTool = tooltipPassword(valid);
            setTextTool(resTool);
            setSavePassword({ ...savePassword, password: event.target.value })
        } else {
            setBtnRegistr(true);
        }
    }

    /**
     * Принял ли клиент политику конфиденциальности
     * @param event состояние checkbox
     */
    const checkValidationPolicy = (event: any) => {
        if (event.target.checked) {
            setStInput({ ...stInput, stchBox: true });
        } else {
            setStInput({ ...stInput, stchBox: false });
        }
    }

    const comparisonPassword = () => {
        if (savePassword.password === savePassword.repeatPassword) {
            setStInput({ ...stInput, stPassword: false });
            setStInput({ ...stInput, stPasswordRepeat: false });
        } else {
            setStInput({ ...stInput, stPassword: true });
            setStInput({ ...stInput, stPasswordRepeat: true });
        }
    }

    const saveRepeatPass = (event: any) => {
        setSavePassword({ ...savePassword, repeatPassword: event.target.value })
    }

    const alertErrorRegistration = (event: boolean) => {
        if (event) {
            return '';
        } else {
            return (
                <Alert severity="error">
                    Ошибка регистрации! Проверьте поля!
                </Alert>
            );
        }
    }

    return (
        <div className='wrapper'>
            <Dialog className='register' open={true}>
                <CapLogin label={'Регистрация нового аккаунта'} />
                {alertErrorRegistration(stErrorFields)}
                <DialogContent>
                    <form onSubmit={handlerSubmit}>
                        <TextField
                            className='register-login'
                            autoFocus
                            margin="dense"
                            id="name"
                            error={stInput.stName}
                            label="Enter Your Nickname"
                            type="text"
                            fullWidth
                            variant="standard"
                            name="name"
                            onInput={checkValidationName}
                        />
                        <TextField
                            className='register-login'
                            margin="dense"
                            id="newemail"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            name="email"
                        />
                        <Tooltip title={`${textTool}`}>
                            <TextField
                                autoComplete='off'
                                className='register-login'
                                margin="dense"
                                id="password"
                                error={stInput.stPassword}
                                name="password"
                                label="Password"
                                type="password"
                                fullWidth
                                variant="standard"
                                onInput={checkValidationPassword}
                            />
                        </Tooltip>
                        <TextField
                            className='register-login'
                            autoComplete='off'
                            margin="dense"
                            error={stInput.stPasswordRepeat}
                            id="repeatpassword"
                            label="Repeat Your Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onInput={saveRepeatPass}
                        />
                        <Box>
                            <Checkbox
                                color="primary"
                                size='sm'
                                id='chkbox'
                                label='Я принимаю'
                                onChange={checkValidationPolicy}
                            />
                            <Link
                                color="primary"
                                level="body-md"
                                variant="plain"
                                href='#'
                            >
                                политику конфиденциальности
                            </Link>
                        </Box>
                        <Button disabled={btnRegistr} className='register-btn-resume' type='submit' fullWidth variant="contained">Зарегистрироваться</Button>
                    </form>
                </DialogContent>
                <Container className='register-wrp'>
                    <Button onClick={() => navigate('/')}>Войти в аккаунт</Button>
                </Container>
            </Dialog>
        </div>
    )
}

export default NewAccount;
