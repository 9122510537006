import {
    DialogTitle
} from '@mui/material';

interface CapLoginProps {
    label: String
}

const CapLogin = ({ label }: CapLoginProps) => {
    return (
        <DialogTitle style={{
            textAlign: "center",
            padding: "15px",
            margin: "0 auto"
        }}>
            <div style={{ display: 'flex' }}>
                <img style={{
                    width: "65px",
                    height: "65px"
                }}
                    src="./logoblack.png"
                    alt="logo" />
                <span style={{
                    display: 'block',
                    alignSelf: 'center',
                    fontSize: "22px",
                    fontWeight: "900",
                    lineHeight: "1",
                    marginLeft: "12px"
                }}>
                    <span style={{ display: 'flex' }}>
                        Rell
                    </span>
                    Technologies
                </span>
            </div>
            <span style={{
                fontSize: "14px",
                fontWeight: "100",
                lineHeight: "0"
            }}> {label} </span>
        </DialogTitle>
    )
}

export default CapLogin;
