import * as React from 'react';

import {Alert} from "@mui/material";

/**
 * Когда пользователь не авторизован
 * */
const AlertUnauthorized = () => {

    return (<Alert color={'error'}>
         Неверный логин или пароль.
    </Alert>);
}

export default AlertUnauthorized;
