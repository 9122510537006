import * as React from 'react';

import {
    Dialog,
    DialogContent,
    Container, Alert,
} from '@mui/material';


import CapLogin from "../../components/account/CapLogotin";

const VerifyEmailFail = () => {


    return (<div className='wrapper'>
            <Dialog className='register' open={true}>
                <CapLogin label={'Регистрация нового аккаунта'}/>
                <DialogContent>
                    <Alert severity="error"
                           style={{
                               margin: '0 20px 20px'
                           }}>
                    При обруботке произошла ошибка. Попробуйте снова или напишите в группу поддеркжи.
                   </Alert>
                </DialogContent>
                <Container className='register-wrp'>
                </Container>
            </Dialog>
        </div>
    )
};

export default VerifyEmailFail;
