import * as React from 'react';

import {
    Dialog,
    DialogContent,
    Container, Button, Alert,
} from '@mui/material';

import CapLogin from "../../components/account/CapLogotin";
import {useNavigate} from "react-router-dom";

const AccountAlreadyExist = () => {

    const navigate = useNavigate();

    return (<div className='wrapper'>
            <Dialog className='register' open={true}>
                <CapLogin label={'Регистрация нового аккаунта'}/>
                <DialogContent>
                    <Alert severity="warning"
                           style={{
                               margin: '0 20px 20px'
                           }}>
                        Данный аккаунт уже зарегестрирован в системе. Пожулайста авторизуйтесь.
                    </Alert>
                </DialogContent>
                <Container className='register-wrp' style={{paddingBottom: 20}}>
                    <Button onClick={() => navigate('/login')}>Авторизоваться</Button>
                </Container>
            </Dialog>
        </div>
    )
};

export default AccountAlreadyExist;
