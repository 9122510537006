import * as React from 'react';

import {
    Dialog,
    DialogContent,
    Container, Alert,
} from '@mui/material';

import CapLogin from "../../components/account/CapLogotin";


const BadRequest = () => {
    return (<div className='wrapper'>
            <Dialog className='register' open={true}>
                <CapLogin label={'Ошибка обработки формы!'}/>
                <DialogContent>
                    <Alert severity="error"
                           style={{
                               margin: '0 20px 20px'
                           }}>
                        Не заданы обязательные поля. Проверьте форму.
                    </Alert>
                </DialogContent>
                <Container className='register-wrp' style={{paddingBottom: 20}}>
                </Container>
            </Dialog>
        </div>
    )
};

export default BadRequest;
